import React from "react";

const Skillset = () => <>
    <h2 className="major">Skillset</h2>
    <h3>Programming languages</h3>
    <dl>
        <dt>Java</dt>
        <dd>main language, 5 years of professional experience</dd>
        <dt>Javascript, Typescript</dt>
        <dd>mainly React and Angular, but also Vue and Node on backend, 5 years of professional experience</dd>
        <dt>SQL</dt>
        <dd>5 years of professional experience</dd>
        <dt>Python</dt>
        <dd>basic knowledge, maintenance of machine learning backend</dd>
        <dt>Scala</dt>
        <dd>no professional experience but I love the language</dd>
    </dl>
    <h3>Technologies, frameworks, software</h3>
    <dl>
        <dt>Spring Boot</dt>
        <dd>~5 years of professional experience</dd>
        <dt>Postgresql and Hibernate</dt>
        <dd>~5 years of professional experience</dd>
        <dt>ArangoDB</dt>
        <dd>~2 years of professional experience</dd>
        <dt>Angular</dt>
        <dd>~4 years of professional experience</dd>
        <dt>React</dt>
        <dd>~2 year of professional experience</dd>
        <dt>Ember</dt>
        <dd>~1 year of professional experience</dd>
        <dt>MongoDB</dt>
        <dd>for personal projects with reactive Spring</dd>
        <dt>Docker</dt>
        <dd>~5 years of professional experience</dd>
        <dt>Kubernetes</dt>
        <dd>~2 years of professional experience</dd>
        <dt>git</dt>
        <dd>~5 years of professional experience</dd>
    </dl>
</>;

export default Skillset;
