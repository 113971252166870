import React from "react";

const Articles = () => <>
    <h2 className="major">My Articles</h2>
    <h3>Baeldung</h3>
    <ul>
        <li>
            <a href="https://www.baeldung.com/feign-retry">
            Retrying Feign Calls
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/java-bitmasking">
            Bitmasking in Java with Bitwise Operators
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/java-iterate-set">
            Iterate Over a Set in Java
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/jdbc-check-table-exists">
            How to Check if a Database Table Exists with JDBC
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/java-hashmap-optimize-performance">
            Optimizing HashMap’s Performance
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/ops/docker-memory-limit">
            Setting Memory And CPU Limits In Docker
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/hibernate-session-object-states">
            Object States in Hibernate’s Session
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/ops/docker-network-information">
            Getting Network Information from Docker
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/java-probability">
            Probability in Java
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/linux/bash-arithmetic-expressions">
            How to Evaluate Arithmetic Expressions in Bash
            </a>
        </li>
        <li>
            <a href="https://www.baeldung.com/linux/bash-string-manipulation">
            String Manipulation in Bash
            </a>
        </li>
    </ul>
</>;

export default Articles;
