import epoint from "../images/logo_epoint_white.png";
import React from "react";

const Work = () => <><h2 className="major">Work</h2>
    <span className="image main">
    <img src={epoint} alt="" />
    </span>
    <h3>E-point S.A. (2017 - today)</h3>
    <p>
        Software house specializing in solutions for financial and e-commerce sectors. I work there as a java developer,
        making mainly backend in various technologies and bits of frontend.
    </p>
    <h3>Applica (2020 - today)</h3>
    <p>
        Startup creating platform for automatic document processing with classification and information extraction.
    </p>
</>;

export default Work;
